<template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div class="row justify-content-between">
          <div class="page-heading"> Mission & Vision: </div>
          <div class="missionContent row">
            <div class="col-8">
              <h2>MISSION</h2>
              <p>
                The Indian Journal of Computer Science and Technology (INDJCST)
                is a scholarly and peer-reviewed journal dedicated to providing
                scientists, engineers, and technicians with the latest
                developments in computer science. The journal offers a window
                into the most recent discoveries in four categories, namely,
                computing (computing theory, scientific computing, cloud
                computing, high-performance computing, numerical and symbolic
                computing), system (database systems, real-time systems,
                operating systems, warning systems, decision support systems,
                information processes, and systems, systems integration),
                intelligence (robotics, bioinformatics, web intelligence,
                artificial intelligence), and application (security, networking,
                software engineering, pattern recognition, e-science, and
                e-commerce, signal and image processing, control theory and
                applications). The editorial board welcomes original,
                substantiated papers on the above topics, which could provoke
                new science interest and benefit those devoted to computer
                science.
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 aimImage">
              <img src="@/assets/Images/Aim.png" alt="img" height="380" />
            </div>
          </div>
          <div class="missionContent">
            <h2>FOCUS AND SCOPE:</h2>
            <h3 class="pb-4">
              The INDJCST welcomes original research papers, technical notes,
              and review articles on the following disciplines:
            </h3>
            <div class="missionList">
              <div class="row">
                <div class="col-6">
                  <ul>
                    <li><p>E-Science And E-Commerce</p></li>
                    <li><p>Signal And Image Processing</p></li>
                    <li><p>Control Theory And Applications</p></li>
                    <li><p>Computing Theory</p></li>
                    <li><p>Scientific Computing</p></li>
                    <li><p>Cloud Computing</p></li>
                    <li><p>High-Performance Computing</p></li>
                    <li><p>Numerical And Symbolic Computing</p></li>
                    <li><p>Database Systems</p></li>
                    <li><p>Real-Time Systems</p></li>
                    <li><p>Operating Systems</p></li>
                    <li><p>Warning Systems</p></li>
                  </ul>
                </div>
                <div class="col-6">
                  <ul>
                    <li><p>Decision Support Systems</p></li>
                    <li><p>Information Processes And Systems</p></li>
                    <li><p>Systems Integration</p></li>
                    <li><p>Robotics</p></li>
                    <li><p>Bioinformatics</p></li>
                    <li><p>Web Intelligence</p></li>
                    <li><p>Artificial Intelligence</p></li>
                    <li><p>Security</p></li>
                    <li><p>Networking</p></li>
                    <li><p>Software Engineering</p></li>
                    <li><p>Pattern Recognition</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h3 {
  padding-bottom: 20px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
}
h5 {
  font-family: "Rubik", sans-serif;
  padding-bottom: 10px;
  font-size: 18px;
}
p {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  color: rgb(100, 117, 137);
  text-align: justify;
  text-justify: inter-word;
}
.missionList {
  margin-left: -20px;
}
.missionContent {
  padding-bottom: 20px;
}
.aimImage {
  margin-top: -30px;
}
.left-side-heading{
  font-weight: bold; 
  line-height: 2;
}
.right-side-heading{
  font-size: 1.5em; 
  font-weight: bold; 
  display: block; 
  margin-bottom: 10px;
}
.page-heading{
  font-size: 1.5em; 
  font-weight: bold; 
  margin-bottom: 20px; 
  display: block
}
h2 {
  font-size: 1.2em;
  margin-bottom: 10px
  
}
h3 {
  font-size: 1em;
}

</style>